import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useRouter} from '../../../../../@casino/hook/useRouter';
import BannerTournament from '../../components/bannerTournament';
import TopPlaces from '../../components/topPlaces';
import CurrentResult from '../../components/currentResult';
import {useStyles} from './helper';
import { setGetLoterryView } from '../../store/thunk/setGetLoterryView';
import { setGetLoterryWin } from '../../store/thunk/setGetLoterryWin';
import { setGetLoterryResult }from '../../store/thunk/setGerLoterryResult';
import Pepsi from '../../../../../@casino/img/pepsi.jpeg';
import MoneyPad from '../../../../../@casino/img/moneypad.jpeg'
import { translate } from '../../../../../dictionaries';

const ViewLoterry = () => {
    document.title = translate('title.lottery');
    const classes = useStyles();
    const dispatch = useDispatch();
    const router = useRouter();

    const {id} = router.match.params;

    React.useEffect(() => {
        dispatch(setGetLoterryResult({
            id
        }))
        dispatch(setGetLoterryView({
            id
        }));
        dispatch(setGetLoterryWin({
            id
        }))
    }, [id]);


    const loterry = useSelector(state => state.loterry.view);
    const win = useSelector(state => state.loterry.win);
    const result = useSelector(state => state.loterry.result);
    
    let month = [
        translate('month.jan'), 
        translate('month.feb'), 
        translate('month.mar'), 
        translate('month.apr'), 
        translate('month.may'), 
        translate('month.jun'), 
        translate('month.jul'), 
        translate('month.avg'), 
        translate('month.sep'), 
        translate('month.oct'), 
        translate('month.nov'), 
        translate('month.dec')
    ];

    return (

        <div className={classes.rootDesktop}>
            <div className={classes.banner}>
                <BannerTournament loterry={loterry}/>
            </div>
            <div style={{width: '100%', marginBottom: 25}}>
                {
                    id == 1 && <img src={Pepsi} alt="" style={{width: '100%'}}/>
                }
                {
                    id == 2 && <img src={MoneyPad} alt="" style={{width: '100%'}}/>  
                }
            </div>
            <div style={{marginBottom: 30}}>
                <h3 style={{
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    color: '#fff', 
                    margin: 0, 
                    padding: 0,
                    fontSize: '30px',
                    fontStyle: 'normal',
                    fontFamily: 'Montserrat, sans-serif',
                    marginBottom: 30
                }}>{translate('lottery.description')}</h3>
                <span style={{
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 400,
                    lineHeight: '24px',
                    color: '#fff',
                    textAlign: 'center'
                }}>
                    {loterry.description}
                </span>
            </div>
            {
                loterry.top_places && 
                <div>
                    <TopPlaces loterry={loterry}/>
                </div>
            }
            <div style={{marginBottom: loterry.status ? 130 : 50}}>
                <h3 style={{
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    color: '#fff', 
                    margin: 0, 
                    padding: 0,
                    fontSize: '30px',
                    fontStyle: 'normal',
                    fontFamily: 'Montserrat, sans-serif',
                    marginBottom: 30
                }}>{translate('lottery.getToTicket')}</h3>
                <ul>
                    <li  style={{
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 400,
                        lineHeight: '24px',
                        color: '#fff',
                    }}>
                        {translate('lottery.ticket')} <span  style={{color: 'gold', fontSize: 24, fontWeight: 700}}>{new Intl.NumberFormat('ru-RU').format(loterry.price_ticket_deposit)}</span> {translate('lottery.deposit')}
                    </li>
                    <li style={{
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 400,
                        lineHeight: '24px',
                        color: '#fff',
                    }}>
                        {translate('lottery.replenishment')} <span  style={{color: 'gold', fontSize: 24, fontWeight: 700}}>{new Intl.NumberFormat('ru-RU').format(loterry.gold_ticket)}</span> {translate('lottery.gamerTwoTicket')} <span  style={{color: 'gold', fontSize: 24, fontWeight: 700}}>{translate('lottery.goldTicket')}</span>, 
                        {translate('lottery.goldTicket')} {loterry.no_double} {translate('lottery.prize')}.
                    </li>
                    <li  style={{
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 400,
                        lineHeight: '24px',
                        color: '#fff',
                    }}>
                        {translate('lottery.startTo')} <span style={{color: 'gold', fontSize: 24, fontWeight: 700}}>03:00 {new Date(+loterry.date_from).getDate() < 10 ? '0' + new Date(+loterry.date_from).getDate() : new Date(+loterry.date_from).getDate()} {month[new Date(+loterry.date_from).getMonth()]} </span> {translate('lottery.po')} <span style={{color: 'gold', fontSize: 24, fontWeight: 700}}>03:00 {new Date(+loterry.date_to).getDate() < 10 ? '0' + new Date(+loterry.date_to).getDate() - 1 : new Date(+loterry.date_to).getDate() - 1} {month[new Date(+loterry.date_to).getMonth()]} </span> {translate('lottery.inclusive')}.
                    </li>
                </ul>
            </div>
            {
                !loterry.status && 
                <div>
                    <h3 style={{
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                        color: '#fff', 
                        margin: 0, 
                        padding: 0,
                        fontSize: '30px',
                        fontStyle: 'normal',
                        fontFamily: 'Montserrat, sans-serif',
                        marginBottom: 30
                    }}>{translate('lottery.result')}</h3>
                    <CurrentResult win={win} />
                </div>
            }
            {
                loterry.status && 
                <div>
                    <h3 style={{
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                        color: '#fff', 
                        margin: 0, 
                        padding: 0,
                        fontSize: '30px',
                        fontStyle: 'normal',
                        fontFamily: 'Montserrat, sans-serif',
                        marginBottom: 30
                    }}>{translate('lottery.currentResult')}</h3>
                    <CurrentResult win={result} current />
                </div>
            }
        </div>
        
    )
}

export default ViewLoterry