import { translate } from '../../../../../dictionaries';
import {postRegistration} from '../api/postRegistration';
import { setPostAuthorization } from './setPostAuthorization';

export const setPostRegistration = (params, setShowModalConfirm, setErrorRegistration, setIsLoading) => {
    return async (dispatch, getState) => {
        try {
            // dispatch(setLoadingAction({loading: true}))
            // params.activeBonus = 'alldeposit'
            params.organization = 1;
            params.utm_source = localStorage.getItem('utm_source');
            params.utm_term = localStorage.getItem('utm_term');
            params.utm_medium = localStorage.getItem('utm_medium');
            params.utm_campaign = localStorage.getItem('utm_campaign');
            params.utm_content = localStorage.getItem('utm_content');

            const {data} = await postRegistration(params);

            if (data.status === 'success') {
                if (params.type === 'email') {
                    dispatch(setPostAuthorization({
                        login: params.login,
                        password: params.password
                    }))
                } else {
                    dispatch(setPostAuthorization({
                        login: params.code + '' + params.login,
                        password: params.password
                    }))
                }
                // setShowModalConfirm(data.data?.messageUuid)
            }
            if (setIsLoading) {
                setIsLoading(false)
            }
        } catch(error) {
            if (error?.response?.data?.message === 'Access Denied') {
                alert('Доступ запрещен');
            } else if (error?.response?.data?.message === 'user is already') {
                if (setErrorRegistration) {
                    setErrorRegistration(true);
                    // window.location.replace(window.location.origin);
                } 
            } else {
                alert(translate('common.success_registration'))
                window.location.replace(window.location.origin)
            }
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}